/** @jsx h **/

import {
  component,
  Fragment,
  h,
  reify,
  Signal,
  StoreCursor,
  When,
} from "alfama";
import { RouterContext } from "alfama-router";

import { BanyanProps, TreeNode } from "../../types";
import { TreeItemActions } from "../TreeItemActions";

export { IModeTypes } from "../../utils/index";

export const TreeNodeLabel = component<
  BanyanProps & {
    cursor: () => StoreCursor<TreeNode>;
    $expanded: Signal<boolean>;
  }
>("TreeNodeLabel", (props, { signal, wire, getContext, onMount }) => {
  const item = reify(props.cursor());
  const $expanded = props.$expanded;

  const expandHandler = async (e: MouseEvent) => {
    e.preventDefault();
    const item = reify(props.cursor());
    if (!item.isDirectory) return;
    if ($expanded.get()) {
      $expanded.set(false);
    } else {
      $expanded.set(true);
      await props.requestExpansion(props.cursor());
    }
  };

  const onClickHandler = async (e: MouseEvent) => {
    e.preventDefault();
    console.log(props.cursor());
    const item = reify(props.cursor());
    if (
      !item.isDirectory ||
      item.name.match(/.space/) ||
      (item.meta && item.meta.type === "thread")
    ) {
      e && e.preventDefault();
      const router = getContext(RouterContext).get();
      const href = (e.target as HTMLLinkElement).href;
      router.navigate(href);
    } else {
      e && e.preventDefault();
      e && e.stopPropagation();
      $expanded.set(!$expanded.get());
      //      console.log(item.path, item.state.loaded);
      await props.requestExpansion(props.cursor());
    }
  };
  const hrefWire = wire(($) => {
    return props.itemHref(props.cursor()) || "";
  });

  //  console.error("item", item, getCursor(props.node));

  return (
    <Fragment>
      <div
        style={`padding-left: ${
          item.path == "/" ? 0 : item.path.split("/").length * 7
        }px;  `}
        onClick={async (e: MouseEvent) => {}}
        class="flex py-1"
      >
        {false && (
          <a
            href={hrefWire}
            onClick={expandHandler}
            key="expandIcon"
            style={item.isDirectory ? `` : `visibility: hidden;`}
            class=""
          >
            <When
              condition={$expanded.get}
              views={{
                true: () => <i key="down" class="ri-arrow-down-s-line"></i>,
                false: () => <i key="up" class="ri-arrow-right-s-line"></i>,
              }}
            />
          </a>
        )}{" "}
        <a
          href={hrefWire}
          onClick={onClickHandler}
          key="icon"
          data-fileicon="true"
          class="w-5 h-5"
        >
          {(() => {
            if (!item.icon) return;
            return item.icon.match(/https:\/\//) ? (
              <span key="icon">
                <img src={item.icon} />
              </span>
            ) : (
              <span key="icon">
                <i class={item.icon} />
              </span>
            );
          })()}
        </a>
        <a
          {...(item.meta?.root
            ? {}
            : {
                href: hrefWire,
              })}
          onClick={onClickHandler}
          class="px-2 text-sm"
          key="name"
          title={wire(($) => $(props.cursor().name))}
        >
          {wire(props.cursor().name as any)}
        </a>
      </div>
    </Fragment>
  );
});
